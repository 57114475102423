import { clsx } from '../../utils/clsx'
import {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  ReactNode,
  isValidElement,
} from 'react'
import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'

type CheckboxProps<T extends FieldValues> = {
  label?: string | ReactNode
  required?: boolean
  error?: string | null
  containerClassName?: string
  inputClassName?: string
  labelClassName?: string
  wrapperClassName?: string
  errorClassName?: string
  id?: string
  register?: UseFormRegister<T>
  name?: Path<T>
  validator?: RegisterOptions
  onChange?: ChangeEventHandler<HTMLInputElement>
  checked?: any
  value?: string
  disabled?: boolean
}
const Checkbox = <T extends FieldValues>(props: CheckboxProps<T>) => {
  const {
    label,
    required = false,
    error,
    containerClassName = '',
    inputClassName = '',
    labelClassName = '',
    wrapperClassName = '',
    errorClassName = '',
    id,
    register,
    name,
    validator,
    onChange,
    value,
    checked,
    disabled = false,
  } = props

  return (
    <div className={clsx('relative flex flex-col mb-2', containerClassName)}>
       <div className={`text-gray-700 ${wrapperClassName}`}>
        <input
          type="checkbox"
          id={id}
          name={name}
          disabled={disabled}
          className={inputClassName}
          checked={checked}
          value={value}
          onChange={onChange}
          required={required}
          {...(register && name && register(name, validator))}
        />
        {typeof label === 'string' && (
          <label
            htmlFor={id}
            className={clsx('mb-0 checkbox-label', labelClassName)}
          >
            {label}
          </label>
        )}
        {isValidElement(label) && label}
      </div>
      {error && (
        <div
          className={clsx('text-danger text-[0.72rem] mt-1', errorClassName)}
        >
          {error}
        </div>
      )}
    </div>
  )
}
export default Checkbox
